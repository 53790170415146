/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import logo from "../../assets/images/logoMiiii.svg";
import minilogo from "../../assets/images/logoMiiii.svg";
import { withRouter } from "react-router-dom/";
import routeList from "../../routeList";
import { fetchAlerts } from "../../store/actions/alerts.actions";
import { fetchTickets } from "../../store/actions/tickets.actions";
import mapTicket from "../../selectors/mapTicket";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  Container,
  ContainerImg,
  ToolBar,
  ImgLogo,
  MiniLogo,
  MenuItemButton,
  ImgItems,
  TextItem,
  LinkStyled,
  Quantity,
  TextAlertItem,
  AlertPointer,
  Box
} from "./styled";

const mapStateToProps = state => {
  const { receivedAlerts, loadingAlerts } = state.alerts;
  const { ticketsList } = state.tickets;
  const { user } = state.auth;
  return {
    isLoading: loadingAlerts,
    alerts: receivedAlerts,
    tickets: ticketsList,
    formatedList: mapTicket(ticketsList),
    user: user
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ fetchAlerts, fetchTickets }, dispatch);

function ToolbarLayout(props) {
  const { children, user, alerts, tickets, formatedList } = props;
  const current_page = props.location ? props.location.pathname : "";
  const { menusAuth } = props.userInfo;
  menusAuth.push({ code: "EXPEN_TEST_BO", isuservisible: false, name: "Expensas Test" });

  const getNeighbourhoods = () => {
    let userNeigh = user.neighbourhoods.filter(x => !x.isparent);
    var neighs = userNeigh
      .map(n => ({ label: n.name, value: n.guid }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    neighs.unshift({ label: "Todos", value: "00000000-0000-0000-0000-000000000000" });
    return neighs;
  };

  let neighbourhoods = getNeighbourhoods();

  const alertQuantity = alerts.filter(x => x.alert_state === "En alerta").length;
  const ticketsQuantity = formatedList[0].length;

  useEffect(() => {
    props.fetchTickets("Todas");
    if (user.neighbourhoods.filter(x => !x.isparent).length > 1) {
      props.fetchAlerts(neighbourhoods.map(x => x.value));
    } else if (user.neighbourhoods.filter(x => !x.isparent).length === 1) {
      props.fetchAlerts(user.neighbourhoods.filter(x => !x.isparent)[0].guid);
    }
  }, []);

  return (
    <Container>
      <ToolBar>
        <ContainerImg id={"Logo-container"}>
          <ImgLogo src={logo} alt="Logo" id={"Main-logo"} />
          <MiniLogo src={minilogo} alt="Mini-Logo" id={"Mini-logo"} />
        </ContainerImg>
        {routeList
          .filter(x => x.showMenu)
          .map((item, index) =>
            menusAuth.filter(x => x.name === item.title).length > 0 ? (
              <LinkStyled key={index} to={item.href}>
                <Box active={item.href === current_page ? true : false}>
                  <MenuItemButton active={item.href === current_page ? true : false}>
                    <ImgItems src={item.img} />
                    {item.title.toLocaleLowerCase() === "alertas" && alertQuantity > 0 ? (
                      <AlertPointer> ! </AlertPointer>
                    ) : null}
                    {item.title.toLocaleLowerCase() === "tickets" && ticketsQuantity > 0 ? (
                      <AlertPointer> ! </AlertPointer>
                    ) : null}
                    {(item.title.toLocaleLowerCase() === "alertas" && alertQuantity > 0) ||
                    (item.title.toLocaleLowerCase() === "tickets" && ticketsQuantity > 0) ? (
                      <TextAlertItem>
                        {item.title}
                        <Quantity>
                          {item.title.toLocaleLowerCase() === "alertas"
                            ? alertQuantity
                            : ticketsQuantity}
                        </Quantity>
                      </TextAlertItem>
                    ) : (
                      <TextItem>{item.title}</TextItem>
                    )}
                  </MenuItemButton>
                </Box>
              </LinkStyled>
            ) : null
          )}
      </ToolBar>
      {children}
    </Container>
  );
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ToolbarLayout);
