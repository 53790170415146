import { combineReducers } from "redux";
import auth from "./store/reducers/auth.reducer";
import userInfo from "./store/reducers/userInfo.reducer";
import lotes from "./store/reducers/lotes.reducer";
import tickets from "./store/reducers/tickets.reducer";
import activities from "./store/reducers/activities.reducer";
import reportes from "./store/reducers/reportes.reducer";
import reservas from "./store/reducers/reservas.reducer";
import spaces from "./store/reducers/spaces.reducer";
import dashboard from "./store/reducers/dashboard.reducer";
import paymentOperations from "./store/reducers/paymentOperations.reducer";
import regulations from "./store/reducers/regulations.reducer";
import paymentClients from "./store/reducers/paymentClients.reducer";
import billetera from "./store/reducers/billetera.reducer";
import clasificados from "./store/reducers/clasificados.reducer";
import padron from "./store/reducers/padron.reducer";
import alerts from "./store/reducers/alerts.reducer";
import proveedores from "./store/reducers/proveedores.reducer";
import selectedResource from "./store/reducers/selectedResource";
import feed from "./store/reducers/feed.reducer";
import communities from "./store/reducers/communities.reducer";
import expenses from "./store/reducers/expenses.reducer";
import reportExpenses from "./store/reducers/reportExpenses.reducer";
import bookings from "./store/reducers/bookings.reducer";
import accesos from "./store/reducers/accesos.reducer";
import invitados from "./store/reducers/invitados.reducer";

// Combine with other reducers we may add in the future
const todoApp = combineReducers({
  userInfo,
  auth,
  lotes,
  tickets,
  activities,
  reportes,
  reservas,
  spaces,
  dashboard,
  paymentOperations,
  paymentClients,
  billetera,
  clasificados,
  selectedResource,
  padron,
  alerts,
  regulations,
  feed,
  proveedores,
  communities,
  expenses,
  reportExpenses,
  accesos,
  invitados,
  bookings
});

export default todoApp;
