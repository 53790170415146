import styled from 'styled-components'
import { Button, } from '@material-ui/core'

export const Header = styled.div`
    width: 100%;
    min-height: 20px;
    max-height: 50px;    
    margin: 30px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`
export const ContentWrapper = styled.div`
    width: 105%;
    max-height: 550px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-wrap: normal;
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black, theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: start;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 10px 10px;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '15px')};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight + '!important' : '2000')};
    margin: ${({ margin }) => (margin ? margin : '10px 10px')};

`
export const LabelContainer = styled.div`
    min-height: 20px;
    max-height: 30px;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    align-self: center;
    justify-content: space-evenly;
    width: ${({ width }) => (width ? width : "25%")};
    margin-left: ${({ margin }) => (margin ? margin : "0px")};
`

export const SelectContainer = styled.div`
    min-height: 20px;
    max-height: 30px;
    margin: 0px 5px;
    width: 15%;
`
export const TableContainer = styled.div`
    width: 100%;
`
export const Row = styled.div`
    min-height: 20px;
    max-height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: ${({ justify }) => (justify ? justify : "flex-start")}; 
`
export const Circle = styled.div`
  
`



export const Status = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ status, theme }) => {
        if (status === "Abonado") {
            return theme.colors.oceanGreen;
        } else if (status === "Pendiente") {
            return theme.colors.mariGold;
        } else {
            return theme.colors.pomegranate;
        }
    }};
    font-size: 18px;
    align-self: center;
    font-weight: 500;
`

export const LoaderWrapper = styled.div`
   width: 50%;
   margin: auto;
   align-self: center;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`
export const Container = styled.div`
    min-width: 500px;
    height: 550px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 0.2s ease;
    box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
    border-radius: 10px;
`

export const CloseIcon = styled.img`
    width: 15px;
    height: 15px;
    padding: 2%;
    align-self: flex-end;
    cursor: pointer;
`
export const RowItem = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
    align-self: ${props => (props.title ? '' : 'center')};
    width: 95%;
    margin: 1% 0%;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};
    padding-top: 1%;
    padding-bottom: 3%;
    align-items: center;
    background-image: linear-gradient(to right,rgba(0,0,0,0.25) 50%,white 50%);
    background-position: bottom;
    background-size: 20px 1px;
    background-repeat: repeat-x;
`
export const Description = styled.div`
    font-size: 15px;
    margin-left: ${props => (props.bold ? '17%' : '0%')};
    width: ${props => (props.bold ? '100%' : '30%')};
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    color: ${props => (props.color ? props.color : 'black')};
    display: flex;
    flex-direction: column-reverse;
`
export const WrapperItems = styled.div`
    width: 100%;
    height: 85%;
    align-self: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: white;
    border-radius: 10px;
    padding: 1% 1%;
`