//components

import Shops from "./containers/Shops";
import PaymentGateway from "./containers/PaymentGateway";
import Padron from "./containers/Padron";
import Dashboard from "./containers/Dashboard";
import Reportes from "./containers/Reportes";
import Billetera from "./containers/Billetera";
import Alertas from "./containers/Alertas";
import Accesos from "./containers/Accesos";
import Expensas from "./containers/Expensas";
import ExpensasCopy from "./containers/Expensas-copy";
import Reservas from "./containers/Bookings";
import Tickets from "./containers/Tickets";
import Error from "./containers/ErrorRoles";
import Invitados from "./containers/Invitados";
import regulations_icon from "./assets/icons/clipboard.svg";
import Regulations from "./containers/Regulations";
import Feed from "./containers/Feed";
import BCRAComunicacionesD from "./containers/Regulations/Components/BCRAComunicacionesD";
import Communities from "./containers/Communities";

// icons
import dashboard from "./assets/icons/dashboard.svg";
import billetera from "./assets/icons/pictogramas BO/1 wallet-3-line.svg";
import feed from "./assets/icons/pictogramas BO/2 rss-feed.svg";
import padron from "./assets/icons/pictogramas BO/3 folder-user-line.svg";
import reportes from "./assets/icons/pictogramas BO/4 bar-chart-2-line.svg";
import shops from "./assets/icons/pictogramas BO/5 shopping-bag-3-line.svg";
import botonDePago from "./assets/icons/pictogramas BO/6 money-dollar-circle-line.svg";
import informasYRegulaciones from "./assets/icons/pictogramas BO/7 file-list-3-line.svg";
import alertas from "./assets/icons/pictogramas BO/8 alarm-warning-line.svg";
import comunidades from "./assets/icons/pictogramas BO/9 user-smile-line.svg";
import expensas from "./assets/icons/pictogramas BO/10 pages-line.svg";
import reservas from "./assets/icons/pictogramas BO/11 calendar-check-line.svg";
import accesos from "./assets/icons/pictogramas BO/12 key-2-line.svg";
import invitados from "./assets/icons/pictogramas BO/13 user-add-line.svg";
import tickets from "./assets/icons/pictogramas BO/14 coupon-3-line.svg";

const routeList = [
  {
    title: "Dashboard",
    img: dashboard,
    href: "/dashboard",
    component: Dashboard,
    showMenu: true
  },
  {
    title: "Billetera",
    img: billetera,
    href: "/billetera",
    component: Billetera,
    showMenu: true
  },
  {
    title: "Feed",
    img: feed,
    href: "/feed",
    component: Feed,
    showMenu: true
  },
  {
    title: "Padrón",
    img: padron,
    href: "/padron",
    component: Padron,
    showMenu: true
  },
  {
    title: "Reportes",
    img: reportes,
    href: "/reportes",
    component: Reportes,
    showMenu: true
  },
  {
    title: "Shops",
    img: shops,
    href: "/shops",
    component: Shops,
    showMenu: true
  },
  {
    title: "Botón de pago",
    img: botonDePago,
    href: "/paymentGateway",
    component: PaymentGateway,
    showMenu: true
  },
  {
    title: "Informes y Regulaciones",
    img: informasYRegulaciones,
    href: "/regulations",
    component: Regulations,
    showMenu: true
  },
  {
    title: "Informes y Regulaciones",
    img: regulations_icon,
    href: "/regulations/detail",
    component: BCRAComunicacionesD,
    showMenu: false
  },
  {
    title: "Alertas",
    img: alertas,
    href: "/alertas",
    component: Alertas,
    showMenu: true
  },
  {
    title: "Comunidades",
    img: comunidades,
    href: "/comunidades",
    component: Communities,
    showMenu: true
  },
  {
    title: "Expensas",
    img: expensas,
    href: "/expensas",
    component: ExpensasCopy,
    showMenu: true
  },
  {
    title: "Expensas Test",
    img: expensas,
    href: "/expensas-test",
    component: Expensas,
    showMenu: true
  },
  {
    title: "Reservas",
    img: reservas,
    href: "/reservas",
    component: Reservas,
    showMenu: true
  },
  {
    title: "Accesos",
    img: accesos,
    href: "/accesos",
    component: Accesos,
    showMenu: true
  },
  {
    title: "Invitados",
    img: invitados,
    href: "/invitados",
    component: Invitados,
    showMenu: true
  },
  {
    title: "Tickets",
    img: tickets,
    href: "/tickets",
    component: Tickets,
    showMenu: true
  },
  {
    title: "Error",
    img: tickets,
    href: "/error",
    component: Error,
    showMenu: false
  }
];

export default routeList;
