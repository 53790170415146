import styled from "styled-components";
import Select from "react-select";

export const RitzTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
`;

export const ContenedorPills = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-template-rows: 1fr;
  grid-column-gap: 3px;
  grid-row-gap: 0px;
  width: 100%;
  border-radius: 100px;
`;

export const DataPills = styled.div`
  border-radius: 6px;
  background-color: white;
  padding: 20px 20px 20px 20px;
`;

export const AddMore = styled.div`
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-style: dashed;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  align-items: center;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #ff2424;
`;

// Side Window

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ flex }) => flex && `flex: ${flex};`}
  & > div:first-child {
    align-items: center;
    h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: #000000;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  & span:first-child {
    font-size: 14px;
    color: #9b9b9b;
    margin-bottom: 10px;
  }
  & span:nth-child(2) {
    font-size: 15px;
    line-height: 1.2;
    color: #000000;
  }
  img {
    width: 53px;
    height: 53px;
    margin-right: 10px;
  }
`;

export const Br = styled.div`
  width: 100%
  border-bottom: solid 1px #f2f2f2;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0px 0px;
  background-color: #001441;
  height: 56px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 20px;
`;
export const Body = styled.div`
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
  border: solid 1px #e1e1e1;
`;

export const ErrorMessage = styled.label`
  margin-right: auto;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.red};
`;

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
`;
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.midGray};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`;

export const InputWrapperMargin = styled.div`
  width: ${({ width }) => width || 100}%;
  margin-right: 25px;
`;
export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  padding: 10px 0;
`;

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`;

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;
export const VehicleInformation = styled.div`
  border-radius: 8px;
  span {
    margin-bottom: 0px !important;
  }
  ${Row} {
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

//Paginate
export const Pages = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const ExtremeButton = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #227462;
  border-radius: 9999px;
  padding: 0 3px 3.5px 0;
  letter-spacing: -3.5px;
  &:hover {
    background-color: #227462;
    color: black;
    opacity: 0.8;
    margin-top: -4px;
    transition: 0.5s;
  }
  cursor: pointer;
  color: #227462;
  font-weight: 1 !important;
  margin-top: 8.7px;
  font-size: 30px;
  backgroud-color: none;
  outline: inherit;
  text-shadow: 0px 0px 0px transparent;
  box-shadow: 0px 0px 0px transparent;
  background: transparent;
`;

// View Family member list

export const BackIcon = styled.img`
  width: 12px;
  margin-right: 10px;
  height: 22px;
  cursor: pointer;
`;
export const SelectWrapper = styled.div`
  width: 150px;
`;

export const FormContainer = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
`;
export const Label = styled.div`
  color: ${({ theme }) => theme.colors.oceanGreen};
  textdecoration: underline;
  cursor: pointer;
`;
export const Cell = styled.div`
  display: flex;
  img {
    width: 48.2px;
    height: 48.2px;
    margin-right: 10px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > span:first-child {
      opacity: 0.8;
      font-size: 13px;
      line-height: 1.54;
      color: #808080;
      white-space: nowrap;
    }
    & > span:nth-child(2) {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.33;
      color: #212223;
      white-space: nowrap;
    }
  }
`;
export const SelectedMember = styled.div`
  display: flex;
  margin-bottom: 10px;
  img {
    width: 53px;
    height: 53px;
    margin-right: 10px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > span:first-child {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.67;
      color: #000000;
      white-space: nowrap;
    }
    & > span:nth-child(2) {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export const DataContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GuestState = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  margin-top: ${({ noMargin }) => (noMargin ? 15 : 40)}px;
  margin-bottom: ${({ noMargin }) => (noMargin ? 15 : 10)}px;
`;
export const ImgContainer = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.ghost};
  border-radius: 5px;
  padding: 4px;

  &:hover {
    cursor: pointer;
  }
`;
export const SelectStyled = styled(Select)`
  width: 20%;
  margin: 0px 10px;
`;
export const MoneyWrapper = styled.div`
  width: 100%;
  padding: 2% 0%;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.athensGray};
  background-color: ${({ theme }) => theme.colors.wildSand};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const MoneyTitle = styled.div`
  color: #30735f;
  font-size: 33px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
`;
export const MoneySubtitle = styled.div`
  color: ${({ theme }) => theme.colors.mineShaft};
  font-size: 18px;
  font-weight: 350;
  margin-top: 10px;
  text-align: center;
`;

export const TitleList = styled.div`
  font-size: 15px;
  font-weight: ${({ bold }) => (bold ? 600 : 0)};
  width: ${({ width }) => width || "20%"};
  margin: ${({ margin }) => margin || "0px"};
`;

export const Status = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  background-color: ${({ background }) => background || "white"};
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 18px;
  align-self: center;
  font-weight: 500;
`;

export const InfiniteLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0;
`;

export const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 20px;
  padding: 20px 0;
`;
export const LoaderWrapper = styled.div`
  margin-left: 80px;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const NoDataMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
