import React from 'react'
import { ContentWrapper, WrapperItems, RowItem, Description, Label, Container, CloseIcon, Row, TableContainer, Circle, ColumnItem, LoaderWrapper, ErrorLabel } from './styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CloseIcon2 from '@material-ui/icons/Close';

import Modal from '../../../../../../components/Modal'
import Loader from "../../../../../../components/Loader"
import DayJS from 'react-dayjs';

//import {getExtendedDate} from '../../../../../../../utils/dateParser'


var formatter = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" });

class ModalDetailTransfer extends React.Component {
    state = {
        transferSelected: [],
        error: '',
        newResultModal: false,
    }

    isValidate = (value) => value == null ? 'No obtenido' : value

    render() {
        var billetera = this.props.billetera
        console.log(billetera)
        return (
            <Modal height={'auto'} width="42%" onClose={this.props.onClose}>

                {billetera.loadingTransactionData === false ? (
                    <WrapperItems>
                        <Label bold fontSize={'28px'} fontWeight={'2000'} margin={'21px 10px'}> Detalle de movimiento: </Label>
                        <RowItem>
                            <Description> Monto: </Description>
                            <Description bold={true}>{formatter.format(this.isValidate(billetera.TransactionData.amount_net))} </Description>
                        </RowItem>
                        <RowItem>
                            <Description> Destino: </Description>
                            {
                                this.isValidate(billetera.TransactionData.destination_bank_account) === "No obtenido" ? <Description bold={true}>No obtenido</Description> :
                                    <Description bold={true}>
                                        <div>{this.isValidate(billetera.TransactionData.destination_bank_account.HolderName)}</div>
                                        <div>{this.isValidate(billetera.TransactionData.destination_bank_account.Cvbu)}</div>
                                    </Description>
                            }
                        </RowItem>
                        <RowItem>
                            <Description> Origen: </Description>
                            {
                                this.isValidate(billetera.TransactionData.source_bank_account) === "No obtenido" ? <Description bold={true}>No obtenido</Description> :
                                    <Description bold={true}>
                                        <div>{this.isValidate(billetera.TransactionData.source_bank_account.HolderName)}</div>
                                        <div>{this.isValidate(billetera.TransactionData.source_bank_account.Cvbu)}</div>
                                    </Description>
                            }
                        </RowItem>
                        <RowItem>
                            <Description> Usuario: </Description>
                            <Description bold={true}>{this.isValidate(billetera.TransactionData.creation_username)}</Description>
                        </RowItem>
                        <RowItem>
                            <Description>Pagado a traves de: </Description>
                            <Description bold={true}>
                                {billetera.TransactionData.payments.length > 0 ? billetera.TransactionData.payments[0].transaction_payment_method : "No obtenido"}
                            </Description>
                        </RowItem>
                        <RowItem>
                            <Description>Fecha y hora: </Description>
                            <Description bold={true}>
                                <DayJS format="dddd, MMMM D, YYYY h:mm A" >{this.isValidate(billetera.TransactionData.date)}</DayJS>
                            </Description>
                        </RowItem>
                        <RowItem>
                            <Description>Cod operacion: </Description>
                            <Description bold={true}>
                                {this.isValidate(billetera.TransactionData.id)}
                            </Description>
                        </RowItem>
                    </WrapperItems>
                ) : (
                    <Loader />
                )}

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        billetera: state.billetera
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalDetailTransfer)

