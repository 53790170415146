import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import compose from "recompose/compose";
import NavBar from "../../components/NavBar";
import MenuItems from "../../components/MenuItems";
import StatusBadge from "../../components/StatusBadge";
import RitzTable from "../../components/RitzTable";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import PropietaryExpensesDetails from "./PropietaryDetails";
import Ordenes from "./Ordenes";
import mapExpensasHome from "../../selectors/mapExpensasHome";
import mapNeighbourhoods from "../../selectors/mapNeighbourhoods";
import mapExpensesHomeDetail from "../../selectors/mapExpensesHomeDetail";
import GenerateExpense from "./GenerateExpense";
import ModalProcessingOrder from "./ModalProcessingOrder";
import { formatCurrencyNumber } from "../../utils/utility";
import {
  fetchHomeExpenses,
  fetchExpensesCliente,
  fetchgetFileDetailClient,
  setPageExpenseList
} from "../../store/actions/expenses.actions";
import NoDataIcon from "../../assets/icons/noDataIcon.jpg";
import ReactPaginate from "react-paginate";

import Select from "react-select";

import {
  Row,
  ContenedorPills,
  DataPills,
  Label,
  SelectWrapper,
  MoneyTitle,
  MoneySubtitle,
  MoneyWrapper,
  LoaderWrapper,
  ExtremeButton,
  Pages,
  RitzTableContainer,
  NoDataMessage
} from "./styled";

const menulistItems = [
  { title: "Detalle", route: "todas", available: true },
  { title: "Órdenes", route: "ordenes", available: true }
];

const meses = [
  { label: "Enero", value: "1" },
  { label: "Febrero", value: "2" },
  { label: "Marzo", value: "3" },
  { label: "Abril", value: "4" },
  { label: "Mayo", value: "5" },
  { label: "Junio", value: "6" },
  { label: "Julio", value: "7" },
  { label: "Agosto", value: "8" },
  { label: "Septiembre", value: "9" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" }
];

let today = new Date();
let mes = today.getMonth();
let anio = today.getFullYear();

var anios = [];

for (var i = 4; i >= 0; i--) {
  anios.push({ label: anio - i, value: anio - i });
}

const columns = [
  { title: "Propietario", field: "client_name", headerStyle: { borderRadius: "10px 0px 0px 6px" } },
  { title: "Barrio", field: "neighborhood_name" },
  {
    title: "Lote",
    field: "property_code",
    cellStyle: {
      paddingLeft: 25 + "px"
    }
  },
  {
    title: "Monto",
    field: "amount",
    render: rowData => formatCurrencyNumber("$", rowData.amount),
    cellStyle: {
      justifyContent: "flex-end",
      alignItems: "flex-end"
    }
  },

  { title: "Cuit", field: "cuit" },
  {
    title: "Estado",
    field: "status_code",
    headerStyle: {
      height: 75 + "px"
    },

    render: rowData => {
      switch (rowData.status_code) {
        case "COMPLETED":
          return <StatusBadge green>Pago</StatusBadge>;
        case "PENDING":
          return <StatusBadge yellow>Pendiente</StatusBadge>;
        case "REJECTED":
          return <StatusBadge red>Rechazado</StatusBadge>;
        default:
          return null;
      }
    }
  },
  {
    title: "Motivo Rechazo",
    field: "motivo_rechazo"
  }
];

// const vec =
//     [{ client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     { client_name: "pedro", neighborhood_name: "Nordelta", property_code: 1, amount: 1500, cuit: "20652868627", status_code: "COMPLETED", actions: "Cocina", motivo_rechazo: "Mala limpieza" },
//     ]

let initialMount = true;
class Expensas extends React.Component {
  state = {
    lote: "",
    neighbourhoodSelected: {
      value: this.props.selectedNeighbourhoodRdx.name
        ? this.props.selectedNeighbourhoodRdx.name
        : this.props.selectedNeighbourhoodRdx.label,
      label: this.props.selectedNeighbourhoodRdx.name
        ? this.props.selectedNeighbourhoodRdx.name
        : this.props.selectedNeighbourhoodRdx.label,
      guid: this.props.selectedNeighbourhoodRdx.guid
    },
    monthSelected: meses[mes],
    yearSelected: {
      label: anio,
      value: anio.toString()
    },
    propietaryDetails: false,
    modalAutomaticExpenses: false,
    modalProcessingOrder: false,
    viewMembers: false, //Renders a different component
    memberDetails: false, //Renders a side-window
    newMemberModal: false, //Render a modal
    editMemberModal: false, //Render a modal
    search: "",
    view: "todas",
    selectedPropietary: null
  };
  componentDidMount() {
    this.setState({
      neighbourhoodSelected: {
        value: this.props.selectedNeighbourhoodRdx.name
          ? this.props.selectedNeighbourhoodRdx.name
          : this.props.selectedNeighbourhoodRdx.label,
        label: this.props.selectedNeighbourhoodRdx.name
          ? this.props.selectedNeighbourhoodRdx.name
          : this.props.selectedNeighbourhoodRdx.label,
        guid: this.props.selectedNeighbourhoodRdx.guid
      },
      monthSelected: meses[mes]
    });

    if (initialMount) {
      this.fetchMoreContent(0);
      initialMount = false;
    }
  }

  handlePageClick = async data => {
    this.fetchMoreContent(data.selected != undefined ? data.selected : data, false);
  };

  fetchMoreContent(data) {
    this.props.setPageExpenseList(data);
    this.props.fetchHomeExpenses(
      this.state.neighbourhoodSelected.guid,
      this.state.yearSelected.value,
      this.state.monthSelected.value
    );
  }

  searchHandleChange = event => {
    this.setState({ search: event.target.value });
  };

  toggleState = (state, resident) => {
    this.setState({ [state]: !this.state[state] });
    if (state === "memberDetails") {
      this.setState({ selectedResident: resident });
    } else {
      this.setState({ selectedResident: resident });
    }
  };

  fetchClientExpenseDetail(row, data) {
    this.switchView("detallePropietario");
    this.setState({ selectedPropietary: data.rowData });
    this.props.fetchExpensesCliente(data.rowData.client_id, this.state.yearSelected.value);
  }

  fetchClientOrdenes(row, data) {
    this.switchView("ordenes");
    this.setState({ selectedPropietary: data.rowData });
    this.props.fetchExpensesCliente(data.rowData.client_id, this.state.yearSelected.value);
  }

  buildNavBarItems = () => {
    const menuToRender = menulistItems.map((item, key) => (
      <MenuItems
        quantity={item.quantity}
        onClick={item.available ? () => this.switchView(item.route) : null}
        active={this.state.view === item.route}
        disabled={!item.available}
        key={key}
      >
        {item.title}
      </MenuItems>
    ));

    return menuToRender;
  };

  handleChangeYear = async value => {
    this.setState({ yearSelected: value });
  };
  handleChangeMonth = value => {
    this.setState({
      monthSelected: value
    });
  };

  handleChangeNeighbourhood = data => {
    this.setState({ neighbourhoodSelected: data });
  };

  searchExpenses = () => {
    this.props.setPageExpenseList(0);
    this.props.fetchHomeExpenses(
      this.state.neighbourhoodSelected.guid,
      this.state.yearSelected.value,
      this.state.monthSelected.value
    );
  };

  switchView = view => {
    this.setState({ view });
  };

  formatNumber(num) {
    if (num) {
      return "ARS " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "ARS 0";
    }
  }

  togglePropietaryDetail() {
    this.setState({ propietaryDetails: !this.state.propietaryDetails });
  }

  toggleModal() {
    this.setState({ modalAutomaticExpenses: !this.state.modalAutomaticExpenses });
  }

  toggleModalProcessingOrder() {
    this.setState({ modalProcessingOrder: !this.state.modalProcessingOrder });
  }

  render() {
    if (this.props.loading) {
      return (
        <LoaderWrapper>
          <Loader medium />
        </LoaderWrapper>
      );
    } else {
      return (
        <>
          {this.state.modalAutomaticExpenses ? (
            <GenerateExpense
              meses={meses}
              onClose={() => this.toggleModal()}
              confirmar={() => this.toggleModalProcessingOrder()}
            />
          ) : null}

          {this.state.modalProcessingOrder ? (
            <ModalProcessingOrder onClose={() => this.toggleModalProcessingOrder()} />
          ) : null}

          <NavBar
            menuListComponent={this.buildNavBarItems()}
            switchView={() => this.switchView()}
            title="Expensas Test"
          >
            {(() => {
              switch (this.state.view) {
                case "todas":
                  return (
                    <>
                      <SectionHeader title="Expensas Test">
                        <SelectWrapper>
                          <Select
                            value={this.state.neighbourhoodSelected}
                            onChange={this.handleChangeNeighbourhood}
                            options={this.props.neighbourhoods}
                          />
                        </SelectWrapper>
                        <SelectWrapper>
                          <Select
                            value={this.state.yearSelected}
                            onChange={this.handleChangeYear}
                            options={anios}
                          />
                        </SelectWrapper>
                        <SelectWrapper>
                          <Select
                            value={this.state.monthSelected}
                            onChange={this.handleChangeMonth}
                            options={
                              this.state.yearSelected.value < anio
                                ? meses
                                : meses.filter((item, index) => index <= mes)
                            }
                          />
                        </SelectWrapper>
                        <Button
                          borderColor="darkgreen"
                          variant="inherit"
                          medium
                          width="162px"
                          onClick={() => this.searchExpenses()}
                        >
                          Buscar
                        </Button>
                        <Button
                          borderColor="darkgreen"
                          variant="inherit"
                          style={{ lineHeight: 0.9 }}
                          medium
                          width="162px"
                          onClick={() => this.toggleModal()}
                        >
                          Cobrar Expensas
                        </Button>
                      </SectionHeader>
                      <Row align={"flex-start"} margin={"10px 0px"}>
                        {this.props?.expensesCabecera.expenses_header != undefined ||
                        this.props.expensesCabecera.length > 0 ||
                        this.props?.expensesCabecera[0]?.expenses_header?.details.length > 0 ? (
                          <ContenedorPills>
                            <DataPills>
                              <MoneyTitle>
                                {this.props.expensesCabecera.length > 0
                                  ? this.formatNumber(this.props.expensesCabecera[0].total_cobrado)
                                  : this.formatNumber(0)}
                              </MoneyTitle>
                              <MoneySubtitle>{"Total de expensas a cobrar"} </MoneySubtitle>
                            </DataPills>
                            <DataPills>
                              <MoneyTitle>
                                {this.props.expensesCabecera.length > 0
                                  ? this.formatNumber(
                                      this.props.expensesCabecera[0].total_pendiente_cobrar
                                    )
                                  : this.formatNumber(0)}
                              </MoneyTitle>
                              <MoneySubtitle>{"Total de expensas cobrado"} </MoneySubtitle>
                            </DataPills>
                            <DataPills>
                              <MoneyTitle>
                                {this.props.expensesCabecera.length > 0
                                  ? this.props.expensesCabecera[0].total_usuario_debito_directo
                                  : "0"}
                              </MoneyTitle>
                              <MoneySubtitle>{"Usuarios con débito directo"} </MoneySubtitle>
                            </DataPills>
                          </ContenedorPills>
                        ) : null}
                      </Row>
                      {this.props?.expensesCabecera.length == 0 ||
                      this.props?.expensesCabecera == [] ||
                      this.props?.expensesCabecera[0]?.expenses_header?.details?.length == 0 ? (
                        <NoDataMessage>
                          <img width={60 + "px"} src={NoDataIcon} />
                          <div>No hay datos</div>
                        </NoDataMessage>
                      ) : (
                        <RitzTableContainer>
                          <RitzTable
                            paging={true}
                            columns={columns.concat({
                              title: "Acciones",
                              headerStyle: {
                                borderRadius: "0px 10px 0px 0px ",
                                height: 75 + "px",
                                alignText: "center",
                                justifyContent: "center"
                              },
                              field: "actions",
                              render: rowData => (
                                <Label
                                  onClick={() =>
                                    this.fetchClientExpenseDetail(rowData, { rowData })
                                  }
                                >
                                  Detalle
                                </Label>
                              )
                            })}
                            //data={this.props.expensesCabecera.length > 0 ? mapExpensesHomeDetail(this.props.expensesCabecera[12-this.state.monthSelected.value].expenses_header) : []}
                            data={
                              this.props.expensesCabecera.length > 0
                                ? mapExpensesHomeDetail(
                                    this.props.expensesCabecera[0].expenses_header
                                  )
                                : []
                            }
                            options={{
                              headerStyle: {
                                width: 100 + "%",
                                backgroundColor: "#7dbe55",
                                color: "#FFFFFF",
                                zIndex: 0,
                                toolbar: false,
                                selection: false,
                                search: false,
                                paging: true,
                                serverSide: false,
                                exportButton: false,
                                exportAllData: false
                              },
                              whiteSpace: "nowrap",
                              rowStyle: rowData => {
                                if (rowData.tableData.id % 2 !== 0) {
                                  return { backgroundColor: "rgba(125,189,86,0.2)" };
                                }
                              }
                            }}
                            isLoading={this.props.loadingfetchHomeExpenses}
                          />
                          <Pages>
                            <ExtremeButton
                              disabled=""
                              id="prevBtn"
                              onClick={() => this.handlePageClick(0)}
                              children={"«"}
                            />
                            <ReactPaginate
                              previousLabel={"‹"}
                              nextLabel={"›"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={5}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick}
                              forcePage={0}
                              containerClassName={"pagination2"}
                              activeClassName={"active"}
                              pageLabelBuilder={page => {
                                return (
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span style={{}}>{page}</span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: 30 + "px",
                                        marginTop: -22 + "px",
                                        height: 10
                                      }}
                                    >
                                      .
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <ExtremeButton
                              disabled=""
                              id="nextBtn"
                              onClick={() => this.handlePageClick(4)}
                              children={"»"}
                            />
                          </Pages>
                        </RitzTableContainer>
                      )}
                    </>
                  );
                case "detallePropietario":
                  return (
                    <>
                      <PropietaryExpensesDetails
                        propietarioDetalles={this.state.selectedPropietary}
                        onClose={() => this.switchView("todas")}
                        loadingPropietaryDetail={this.props.loadingfetchExpensesCliente}
                        detalle={this.props.ExpensesCliente}
                        fetchgetFileDetailClient={this.props.fetchgetFileDetailClient}
                        fileDetailClient={this.props.fileDetailClient}
                      />
                    </>
                  );
                case "ordenes":
                  return (
                    <>
                      <Ordenes
                        {...this.state}
                        switchView={this.switchView}
                        onClick={() => this.searchExpenses()}
                      />
                    </>
                  );
              }
            })()}
          </NavBar>
        </>
      );
    }
  }
}

const mapStateToProps = ({
  userInfo: { neighbourhoods, selectedNeighbourhood, menusAuth },
  userInfo,
  expenses
}) => {
  return {
    loading: expenses.loadingOrdersList || expenses.loadingExpenses,
    loadingExpenses: expenses.loadingExpenses,
    expensesCabecera: mapExpensasHome(expenses),
    neighbourhoods: mapNeighbourhoods(neighbourhoods),
    loadingfetchHomeExpenses: expenses.loadingfetchHomeExpenses,
    //expensasDetails: mapExpensesHomeDetail(expenses),
    selectedNeighbourhoodRdx: userInfo.selectedNeighbourhood,
    fetchExpensesClienteSuccess: expenses.fetchExpensesClienteSuccess,
    fetchExpensesClienteFail: expenses.fetchExpensesClienteFail,
    loadingfetchExpensesCliente: expenses.loadingfetchExpensesCliente,
    ExpensesCliente: expenses.ExpensesCliente,
    fileDetailClient: expenses.fileDetailClient,
    pageExpenses: expenses.pageExpenses
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchHomeExpenses,
      fetchExpensesCliente,
      fetchgetFileDetailClient,
      setPageExpenseList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(compose()(Expensas));
