import React from "react";
import NavBar from "../../components/NavBar";
import Button from "../../components/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Arrow from "../../assets/icons/mini-right-arrow.png";
import Notificacion from "../../assets/icons/notification.png";
import Reservas from "../../assets/icons/schedule.png";

import { Pie, Bar } from "react-chartjs-2";

import { fetchBilleteraDash, fetchPersonaDash } from "../../store/actions/dashboard.actions";

import {
  GridContainer,
  Card,
  CardTitle,
  CardContent,
  CardValue,
  CardSubTitle,
  CardRow,
  CardRowButton,
  CardRowIcon,
  IconArrow,
  Icon,
  Title
} from "./styled";

class Dashboard extends React.Component {
  state = {};

  formatNumber(num) {
    if (num) {
      return "ARS " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "ARS 0";
    }
  }

  componentDidMount() {
    this.props.fetchBilleteraDash();
    this.props.fetchPersonaDash();
  }

  render() {
    let data;
    let dataBarrios;

    data = {
      labels: ["Total Usuarios", "Miiii Cuentas CVU"],
      datasets: [
        {
          data: [
            this.props.dash.dashPersonaData.TotalPersonas,
            this.props.dash.dashBilleteraData.TotalCvuMiiii
          ],
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"]
        }
      ]
    };

    const options = {
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true
          }
        ]
      }
    };

    dataBarrios = {
      labels: ["Nordelta", "Puertos"],
      datasets: [
        {
          barPercentage: 0.5,
          minBarLength: 2,
          label: "Usuarios por Barrio",
          data: [
            this.props.dash.dashPersonaData.TotalUsuariosAvn,
            this.props.dash.dashPersonaData.TotalUsuariosAvp
          ],
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"]
        }
      ]
    };

    return (
      <NavBar overflowHidden={true} hideMenu={true}>
        <Title>Dashboard</Title>
        <GridContainer>
          <Card>
            <CardTitle>Usuarios</CardTitle>
            <CardContent>
              {this.props.dash.fetchDashPersonaSuccess ? (
                <>
                  <CardRow>
                    <CardSubTitle>Cantidad de usuarios totales</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalPersonasUsuarios
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  <CardRow>
                    <CardSubTitle>Cantidad de usuarios AVN</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalUsuariosAvn
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  <CardRow>
                    <CardSubTitle>Cantidad de usuarios AVP</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalUsuariosAvp
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  <CardRow>
                    <CardSubTitle>Cantidad de usuarios MA</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalUsuariosMercadoAbierto
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  <CardRow>
                    <CardSubTitle>Total Propietarios</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalPropietarios
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  <CardRow>
                    <CardSubTitle>Propietarios con usuario</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalPropietariosUsuarios
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  <CardRow>
                    <CardSubTitle>Propietarios sin usuario</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalPropietariosSinUsuario
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  <CardRow>
                    <CardSubTitle>Puertos Life</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashPersonaSuccess
                        ? this.props.dash.dashPersonaData.TotalUsuariosPuertosLife
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                </>
              ) : null}
            </CardContent>
          </Card>
          {/*}
          Billeteras
    
          * Cantidad de Billeteras BO Barrios
          * Cantidad de Billeteras BO Comercios
          * Cantidad de Billeteras Usuarios
          
          Débitos
          * Cantidad de adhesiones totales 
          * Cantidad de adhesiones al débitos por barrio padre

          Cuentas Itau
          * Cantidad de cuentas Itau totales
          * Cantidad de cuentas Itau por barrio padre
          * 
          * 
          */}
          <Card>
            <CardTitle>Billetera</CardTitle>
            {this.props.dash.fetchDashBilleteraSuccess ? (
              <CardContent>
                <CardRow>
                  <CardSubTitle>Balance total</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.formatNumber(this.props.dash.dashBilleteraData.TotalBalanceMiiii)
                      : "Loading..."}
                  </CardValue>
                </CardRow>
                <CardRow>
                  <CardSubTitle>Balance consolidado</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.formatNumber(
                          this.props.dash.dashBilleteraData.TotalBalanceConsolidadoMiiii
                        )
                      : "Loading..."}
                  </CardValue>
                </CardRow>
                <CardRow>
                  <CardSubTitle>Entradas pendientes</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.formatNumber(
                          this.props.dash.dashBilleteraData.TotalBalancePendienteEntranteMiiii
                        )
                      : "Loading..."}
                  </CardValue>
                </CardRow>
                <CardRow>
                  <CardSubTitle>Salidas pendientes</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.formatNumber(
                          this.props.dash.dashBilleteraData.TotalBalancePendienteSalienteMiiii
                        )
                      : "Loading..."}
                  </CardValue>
                </CardRow>
                <CardRow>
                  <CardSubTitle>Billeteras totales</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.props.dash.dashBilleteraData.TotalClientesBilletera
                      : "Loading..."}
                  </CardValue>
                </CardRow>
                <CardRow>
                  <CardSubTitle>Billeteras solo clientes</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.props.dash.dashBilleteraData.TotalCvuMiiii
                      : "Loading..."}
                  </CardValue>
                </CardRow>
                {/* <CardRow>
                <CardSubTitle>BO Barrios</CardSubTitle>
                <CardValue>
                  {this.props.dash.fetchDashBilleteraSuccess ? '$ ' + this.props.dash.dashBilleteraData.TotalBalanceMiiii : 'Loading...'}
                </CardValue>
              </CardRow> */}
                <CardRow>
                  <CardSubTitle>Total de CVU Miiii</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.props.dash.dashBilleteraData.TotalCvuMiiii
                      : "Loading..."}
                  </CardValue>
                </CardRow>
              </CardContent>
            ) : null}
            <br />
            <CardTitle>Debitos</CardTitle>
            {this.props.dash.fetchDashBilleteraSuccess ? (
              <CardContent>
                <CardRow>
                  <CardSubTitle>Adhesiones Debito directo</CardSubTitle>
                  <CardValue>
                    {this.props.dash.fetchDashBilleteraSuccess
                      ? this.props.dash.dashBilleteraData.TotalDebitosDirectos
                      : "Loading..."}
                  </CardValue>
                </CardRow>
              </CardContent>
            ) : null}
          </Card>

          <Card>
            <CardTitle>Itau</CardTitle>
            {this.props.dash.fetchDashBilleteraSuccess ? (
              <>
                <CardContent>
                  <CardRow>
                    <CardSubTitle>Totales</CardSubTitle>
                    <CardValue>
                      {this.props.dash.fetchDashBilleteraSuccess
                        ? this.props.dash.dashBilleteraData.TotalUsuariosCuentasItau
                        : "Loading..."}
                    </CardValue>
                  </CardRow>
                  {/* <CardRow>
                <CardSubTitle>x Barrio Padre</CardSubTitle>
                <CardValue>
                  this.props.dash.fetchDashBilleteraSuccess ? this.props.dash.dashBilleteraData.TotalCuentasPorBanco['BANCO ITAU ARGENTINA S.A.'] : 'Loading...'
                
                </CardValue>
              </CardRow> 
              */}
                </CardContent>
                <br />
                <CardContent>
                  <CardRow>
                    <CardSubTitle>Total Cuentas</CardSubTitle>
                    <Pie data={data} />
                    <CardSubTitle>Usuarios por Barrio</CardSubTitle>
                    <Bar data={dataBarrios} options={options} />
                  </CardRow>
                </CardContent>
              </>
            ) : null}
          </Card>
        </GridContainer>
      </NavBar>
    );
  }
}

const mapStateToProps = state => {
  return {
    dash: state.dashboard
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBilleteraDash,
      fetchPersonaDash
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
