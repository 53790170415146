import { createSelector } from "reselect";

const whatMonth = date => {
  var dateformat = new Date(date);
  return dateformat.getMonth() + 1;
};

// Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapReports = createSelector(
  rep => rep,
  rep =>
    Array.isArray(rep.reports)
      ? rep.reports.map(repor => ({
          bank_agreement: repor.bank_agreement,
          creation_date: repor.creation_date,
          creation_user: repor.creation_user,
          expense_bank_direct_debit_report_state: {
            id: repor.expense_bank_direct_debit_report_state.id,
            code: repor.expense_bank_direct_debit_report_state.code,
            name: repor.expense_bank_direct_debit_report_state.name
          },
          expense_bank_direct_debit_report_state_id:
            repor.expense_bank_direct_debit_report_state_id,
          file_text: repor.file_text,
          headers: repor.headers,
          id: repor.id,
          last_update: repor.last_update,
          max_due_date: repor.max_due_date,
          message: repor.message,
          payment_method: repor.payment_method,
          period_date: repor.period_date,
          process_operation_type: repor.process_operation_type,
          send_expenses_to_bank: repor.send_expenses_to_bank,
          sent_date: repor.sent_date,
          total_debits_amount: repor.total_debits_amount,
          total_debits_count: repor.total_debits_count
        }))
      : rep
);

export default mapReports;
