import {
  FETCH_GET_HOME_EXPENSES_SUCCESS,
  FETCH_GET_HOME_EXPENSES_START,
  FETCH_GET_HOME_EXPENSES_FAIL,
  SET_DETAIL_PAGE,
  FETCH_GET_EXPENSES_CLIENTE_SUCCESS,
  FETCH_GET_EXPENSES_CLIENTE_START,
  FETCH_GET_EXPENSES_CLIENTE_FAIL,
  FETCH_POST_UPLOAD_FILE_SUCCESS,
  FETCH_POST_UPLOAD_FILE_START,
  FETCH_POST_UPLOAD_FILE_FAIL,
  FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS,
  FETCH_GET_FILE_DETAIL_CLIENT_FAIL,
  FETCH_GET_FILE_DETAIL_CLIENT_START,
  FETCH_AUTOMATIC_GENERATE_EXPENSES_SUCCESS,
  FETCH_AUTOMATIC_GENERATE_EXPENSES_START,
  FETCH_AUTOMATIC_GENERATE_EXPENSES_FAIL,
  FETCH_AUTOMATIC_GENERATE_EXPENSES_RESET,
  FETCH_GENERATE_EXPENSES_SUCCESS,
  FETCH_GENERATE_EXPENSES_START,
  FETCH_GENERATE_EXPENSES_FAIL,
  FETCH_REPORTE_EXPENSES_SUCCESS,
  FETCH_REPORTE_EXPENSES_START,
  FETCH_REPORTE_EXPENSES_FAIL
} from "./actionTypes";
import { saveAs } from "file-saver";

import getApi from "../../shared/api";

import {
  postGenerateExpensesAPI,
  postGenerateAutomaticExpensesAPI,
  postGenerateXlsxExpensesApi,
  postGenerateTxtExpensesApi,
  getHomeExpenses,
  getExpensesPorCliente,
  getFileDetailClient,
  getFileDetailNeighbourhood,
  postExecuteExpensesReportAPI,
  postUploadFile,
  getReports
} from "../../apiConstants";
import mapExpenses from "../../selectors/mapCommunities";
import { ContactSupportOutlined } from "@material-ui/icons";
import { exportFromJson, lowerFirstLetter } from "./xlsxExportHelper";

export const setPageExpenseList = page => {
  return {
    type: SET_DETAIL_PAGE,
    page: page
  };
};

export const fetchHomeExpensesSuccess = homeExpenses => {
  return {
    type: FETCH_GET_HOME_EXPENSES_SUCCESS,
    data: homeExpenses
  };
};

export const fetchHomeExpensesFail = error => {
  return {
    type: FETCH_GET_HOME_EXPENSES_FAIL,
    error: error
  };
};

export const fetchHomeExpensesStart = () => {
  return {
    type: FETCH_GET_HOME_EXPENSES_START
  };
};

export const fetchHomeExpenses = (guidParam, year, month) => {
  //export const fetchHomeExpenses = (guidParam, year, lote) => {
  return async (dispatch, getState) => {
    dispatch(fetchHomeExpensesStart());
    const { expenses } = getState();
    let api = await getApi();
    const page = expenses.pageExpenses;
    //var url = getHomeExpenses + 'NeighborhoodGuid=' + guidParam + '&PropertyCode=' + lote + '&PeriodYear=' + year + '&PageSize=10&PageNumber=' + page
    // var url = getHomeExpenses + 'NeighborhoodGuid=' + guidParam + '&PeriodYear=' + year + '&PageSize=12&PageNumber=' + page
    var url =
      getHomeExpenses +
      "NeighborhoodGuid=" +
      guidParam +
      "&PeriodYear=" +
      year +
      "&PageSize=1&PageNumber=" +
      page +
      "&PeriodMonth=" +
      month;

    try {
      const response = await api.get(url);
      dispatch(fetchHomeExpensesSuccess(response.data));
    } catch (error) {
      dispatch(fetchHomeExpensesFail(error));
    }
  };
};

export const fetchClienteExpensesSuccess = clientExpenses => {
  return {
    type: FETCH_GET_EXPENSES_CLIENTE_SUCCESS,
    data: clientExpenses
  };
};

export const fetchClienteExpensesFail = error => {
  return {
    type: FETCH_GET_EXPENSES_CLIENTE_FAIL,
    error: error
  };
};

export const fetchClienteExpensesStart = () => {
  return {
    type: FETCH_GET_EXPENSES_CLIENTE_START
  };
};

export const fetchExpensesCliente = (clientId, year) => {
  return async (dispatch, getState) => {
    dispatch(fetchClienteExpensesStart());
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState();
    const api = await getApi(guid);
    var url =
      getExpensesPorCliente + clientId + "&PeriodYear=" + year + "&PageSize=24&PageNumber=0";
    try {
      const response = await api.get(url);
      dispatch(fetchClienteExpensesSuccess(response.data));
    } catch (error) {
      dispatch(fetchClienteExpensesFail(error));
    }
  };
};

export const fetchUploadFileSuccess = file => {
  return {
    type: FETCH_POST_UPLOAD_FILE_SUCCESS,
    file: file
  };
};

export const fetchUploadFileFail = error => {
  return {
    type: FETCH_POST_UPLOAD_FILE_FAIL,
    error: error
  };
};

export const fetchUploadFileStart = () => {
  return {
    type: FETCH_POST_UPLOAD_FILE_START
  };
};

export const fetchUploadFile = body => {
  return async (dispatch, getState) => {
    dispatch(fetchUploadFileStart());
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState();
    const api = await getApi(guid);

    var BodyFormatted = { ResultFile: "" };
    BodyFormatted.ResultFile = body;

    try {
      const response = await api.post(postUploadFile, BodyFormatted);
      dispatch(fetchUploadFileSuccess(response.data));
    } catch (error) {
      dispatch(fetchUploadFileFail(error.response.data.message));
    }
  };
};

export const fetchGenerateAutomaticExpensesSuccess = expensas => {
  return {
    type: FETCH_AUTOMATIC_GENERATE_EXPENSES_SUCCESS,
    expensas: expensas
  };
};

export const fetchGenerateXlsx = expensas => {
  let columns = [];
  columns.push(expensas);

  const columnsData = columns.map(column => ({
    file_text: column.file_text
  }));

  const columnsTitle = ["file_text"];

  exportFromJson(
    columnsData,
    // columnsData.map(x => x.name),
    columnsTitle,
    lowerFirstLetter("expenses")
  );
  return { fetchGenerateXlsx };
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fetchGenerateTxt = expensas => {
  console.log("expensas", expensas);
  const blob = new Blob([expensas.file_text], { type: "text/plain;charset=utf-8" });
  saveAs(blob, "file_text.txt");
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fetchGenerateAutomaticExpensesFail = error => {
  return {
    type: FETCH_AUTOMATIC_GENERATE_EXPENSES_FAIL,
    error: error
  };
};

export const fetchGenerateXlsxFail = error => {
  return {
    type: FETCH_AUTOMATIC_GENERATE_EXPENSES_FAIL,
    error: error
  };
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fetchGenerateTxtFail = error => {
  return {
    type: FETCH_AUTOMATIC_GENERATE_EXPENSES_FAIL,
    error: error
  };
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fetchGenerateAutomaticExpensesStart = () => {
  return {
    type: FETCH_AUTOMATIC_GENERATE_EXPENSES_START
  };
};

export const fetchGenerateAutomaticExpensesReset = () => {
  return {
    type: FETCH_AUTOMATIC_GENERATE_EXPENSES_RESET
  };
};

//NO USAR

export const fetchGenerateAutomaticExpensas = bodyContent => {
  return async (dispatch, getState) => {
    dispatch(fetchGenerateAutomaticExpensesStart());
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState();
    const api = await getApi();
    try {
      const response = await api.post(postGenerateAutomaticExpensesAPI, bodyContent);
      dispatch(fetchGenerateAutomaticExpensesSuccess(response.data));
    } catch (error) {
      dispatch(fetchGenerateAutomaticExpensesFail(error.response.data.message));
    }
  };
};

export const fetchGenerateAutomaticXlsx = bodyContent => {
  return async dispatch => {
    const api = await getApi();
    try {
      const response = await api.get(postGenerateXlsxExpensesApi, bodyContent);
      dispatch(fetchGenerateXlsx(response?.data));
    } catch (error) {
      dispatch(fetchGenerateXlsxFail(error?.response?.data?.message));
    }
  };
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fetchGenerateAutomaticTxt = bodyContent => {
  console.log("bodyContent", bodyContent);
  return async dispatch => {
    const api = await getApi();
    try {
      const response = await api.get(postGenerateTxtExpensesApi, bodyContent);
      console.log("response", response);
      dispatch(fetchGenerateTxt(response?.data));
    } catch (error) {
      dispatch(fetchGenerateTxtFail(error?.response?.data?.message));
    }
  };
};

export const fetchGenerateAutomaticTxtReports = bodyContent => {
  console.log("bodyContent", bodyContent);
  return async dispatch => {
    dispatch(fetchGenerateTxt(bodyContent));
  };
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////
//--------------- Get the File detail client ------------------------
export const fetchgetFileDetailClientSuccess = fileDetailClient => {
  return {
    type: FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS,
    fileDetailClient: fileDetailClient
  };
};

export const fetchgetFileDetailClientFail = error => {
  return {
    type: FETCH_GET_FILE_DETAIL_CLIENT_FAIL,
    error: error
  };
};

export const fetchgetFileDetailClientStart = () => {
  return {
    type: FETCH_GET_FILE_DETAIL_CLIENT_START
  };
};

export const fetchgetFileDetailClient = idDetail => {
  return async (dispatch, getState) => {
    dispatch(fetchgetFileDetailClientStart());
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState();
    const api = await getApi(guid);
    var url = getFileDetailClient + idDetail;
    try {
      const response = await api.get(url);
      dispatch(fetchgetFileDetailClientSuccess(response.data));
    } catch (error) {
      dispatch(fetchgetFileDetailClientFail(error));
    }
  };
};

//--------------- Generate file expensas for the bank ------------------------
export const fetchGenerateExpensasSuccess = expensas => {
  return {
    type: FETCH_GENERATE_EXPENSES_SUCCESS,
    expensas: expensas
  };
};

export const fetchGenerateExpensasFail = error => {
  return {
    type: FETCH_GENERATE_EXPENSES_FAIL,
    error: error
  };
};

export const fetchGenerateExpensasStart = () => {
  return {
    type: FETCH_GENERATE_EXPENSES_START
  };
};

export const fetchReportsSuccess = reports => {
  return {
    type: FETCH_REPORTE_EXPENSES_SUCCESS,
    reports: reports
  };
};

export const fetchReportsFail = error => {
  return {
    type: FETCH_REPORTE_EXPENSES_FAIL,
    error: error
  };
};

export const fetchGenerateExpensas = bodyContent => {
  return async (dispatch, getState) => {
    dispatch(fetchGenerateExpensasStart());
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState();
    const api = await getApi(guid);
    try {
      const response = await api.post(postGenerateExpensesAPI, bodyContent);
      dispatch(fetchGenerateExpensasSuccess(response.data));
    } catch (error) {
      dispatch(fetchGenerateExpensasFail(error.response.data.message));
    }
  };
};

export const fetchReportsStart = () => {
  return {
    type: FETCH_REPORTE_EXPENSES_START
  };
};

// export const fetchReports = category => {
//   return async (dispatch, getState) => {
//     dispatch(fetchReportsStart());
//     const {
//       userInfo: {
//         selectedReport: { guid }
//       }
//     } = getState();

//     const api = await getApi(guid, true);

//     try {
//       // Fetching content
//       const response = await api.get(`${getReports}${category}`);

//       dispatch(
//         fetchReportsSuccess({
//           values: response.data
//         })
//       );
//     } catch (error) {
//       dispatch(fetchReportsFail(error));
//     }
//   };
// };

export const setPageReportsList = page => {
  return {
    type: SET_DETAIL_PAGE,
    page: page
  };
};

export const fetchReports = month => {
  return async (dispatch, getState) => {
    dispatch(fetchReportsStart());

    const state = getState();
    const guid = state?.userInfo?.selectedReport?.guid;

    // if (!guid) {
    //   // Handle the case where guid is undefined
    //   dispatch(fetchReportsFail(new Error("GUID not found")));
    //   return;
    // }

    const api = await getApi(guid, true);

    try {
      // Fetching content
      const response = await api.get(`${getReports}${month}`);
      // console.log("response", response);

      dispatch(
        fetchReportsSuccess({
          values: response.data
        })
      );
    } catch (error) {
      dispatch(fetchReportsFail(error));
    }
  };
};
