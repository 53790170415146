/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import RitzTable from "../../../components/RitzTable";
import Modal from "../../Modal";
import Button from "../../../components/Button";
import { bindActionCreators } from "redux";
import SectionHeader from "../../../components/SectionHeader";
import CancelIcon from "@material-ui/icons/Clear";
import BookingModal from "../../../containers/Bookings/SpacesList/SchedulesModal/ScheduleModal/BookingModal";

import {
  fetchScheduleBookings,
  createBookingNeighbourhood,
  fetchSchedulesCalendar
} from "../../../store/actions/bookings.actions";

var moment = require("moment");

const mapStateToProps = state => {
  const {
    isLoading,
    scheduleBookings,
    loadingFetchScheduleBookings,
    errorFetchBookings
  } = state.bookings;

  return {
    isLoading,
    scheduleBookings,
    loadingFetchScheduleBookings,
    errorFetchBookings
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchScheduleBookings,
      createBookingNeighbourhood,
      fetchSchedulesCalendar
    },
    dispatch
  );

function MultipleBookingDetailModal(props) {
  const {
    isLoading,
    scheduleBookings,
    loadingFetchScheduleBookings,
    errorFetchBookings,
    fetchScheduleBookings,
    originalData,
    date,
    bookingForm,
    createBookingNeighbourhood,
    fetchSchedulesCalendar
  } = props;

  const [showBookingModal, setShowBookingModal] = useState(false);

  useEffect(() => {
    fetchScheduleBookings(originalData.schedule_id, moment.utc(date));
  }, []);

  const toggleShowBookingModal = () => {
    setShowBookingModal(!showBookingModal);
  };

  const onModalClose = () => {
    props.onClose();
    fetchSchedulesCalendar(originalData.space_id, props.selectedCalendarDateTime);
  };

  var columns = [
    { title: "Reservado por", field: "full_name" },
    { title: "Teléfono", field: "phone_number" },
    { title: "Importe", field: "price" },
    { title: "Desde", field: "from" },
    { title: "Hasta", field: "to" },
    { title: "Descripción", field: "description" }
    // { title: "A nombre de:", field: "full_name_booking" },
    // { title: "Observaciones:", field: "observations" }
  ];

  const bookingModal = (
    <BookingModal
      spaceId={props.originalData.spaceId}
      selectedCalendarDateTime={props.originalData.selectedCalendarDateTime}
      title="Nueva Reserva"
      onClose={() => setShowBookingModal(false)}
      leftPopUp={props.originalData.leftPopUp}
      bottomPopUp={props.originalData.bottomPopUp}
      date={props.date}
      from={props.originalData?.from}
      to={props.originalData?.to}
      schedule_id={props.originalData?.schedule_id}
      createBookingNeighbourhood={createBookingNeighbourhood}
      fromMultiplebooking={true}
      fetchScheduleBookings={fetchScheduleBookings}
    />
  );

  return (
    <Modal
      title={
        "Reservas del dia " +
        moment.utc(props.date).format("DD-MM-YYYY") +
        " turno: " +
        props.originalData.from +
        " - " +
        props.originalData.to
      }
      onClose={onModalClose}
      height={"min-height"}
      width="auto"
    >
      {props.originalData.booking_quantity < props.originalData.available_places && (
        <SectionHeader width="100%" fontWeight={"bold"}>
          <Button width="9.8em" onClick={() => toggleShowBookingModal()}>
            Agregar reserva
          </Button>
        </SectionHeader>
      )}
      <RitzTable
        columns={columns}
        title=""
        isLoading={loadingFetchScheduleBookings}
        options={{
          toolbar: false,
          paging: true,
          serverSide: false,
          selection: false,
          exportButton: false,
          exportAllData: false,
          exportFileName: ""
        }}
        actions={[
          {
            icon: CancelIcon,
            tooltip: "Cancelar",
            isFreeAction: false,
            onClick: (event, rowData) => props.cancelFunc(rowData.id)
          }
        ]}
        data={scheduleBookings}
      />
      {showBookingModal ? bookingModal : null}
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleBookingDetailModal);
